export enum PaymentTypes {
    /** PaymentsPaymentTypes.GeneralExpenses = 1 */
    GeneralExpenses = 1,

    /** PaymentsPaymentTypes.DeductibleExpenses = 2 */
    DeductibleExpenses = 2,

    /** PaymentsPaymentTypes.NonCashDeductibleExpenses = 3 */
    NonCashDeductibleExpenses = 3,

    /** PaymentsPaymentTypes.MortgageRepayments = 4 */
    MortgageRepayments = 4,

    /** PaymentsPaymentTypes.InvestmentLoanRepayments = 5 */
    InvestmentLoanRepayments = 5,

    /** PaymentsPaymentTypes.PersonalLoanRepayments = 6 */
    PersonalLoanRepayments = 6,

    /** PaymentsPaymentTypes.CreditCards = 7 */
    CreditCards = 7,

    /** PaymentsPaymentTypes.CarLoanRepayments = 8 */
    CarLoanRepayments = 8,

    /** PaymentsPaymentTypes.OtherRepayments = 9 */
    OtherRepayments = 9,

    /** PaymentsPaymentTypes.CalculatedTax = 10 */
    CalculatedTax = 10,

    /** PaymentsPaymentTypes.OtherTax = 11 */
    OtherTax = 11,

    /** PaymentsPaymentTypes.InvestmentContributions = 12 */
    InvestmentContributions = 12,

    /** PaymentsPaymentTypes.InvestmentExpenses = 13 */
    InvestmentExpenses = 13,

    /** PaymentsPaymentTypes.Savings = 14 */
    Savings = 14,

    /** PaymentsPaymentTypes.AdviceExpenses = 15 */
    AdviceExpenses = 15,

    /** PaymentsPaymentTypes.OtherInvestmentExpenses = 16 */
    OtherInvestmentExpenses = 16,

    /** PaymentsPaymentTypes.PersonalInsuranceDeductible = 17 */
    PersonalInsuranceDeductible = 17,

    /** PaymentsPaymentTypes.PersonalInsuranceNonDeductible = 18 */
    PersonalInsuranceNonDeductible = 18,

    /** PaymentsPaymentTypes.HealthInsurance = 19 */
    HealthInsurance = 19,

    /** PaymentsPaymentTypes.HomeAndContentsInsurance = 20 */
    HomeAndContentsInsurance = 20,

    /** PaymentsPaymentTypes.VehicleInsurance = 21 */
    VehicleInsurance = 21,

    /** PaymentsPaymentTypes.OtherInsuranceExpenses = 22 */
    OtherInsuranceExpenses = 22,

    /** PaymentsPaymentTypes.PostTaxSuperContributions = 23 */
    PostTaxSuperContributions = 23,

    /** PaymentsPaymentTypes.PreTaxSuperContributions = 24 */
    PreTaxSuperContributions = 24,

    /** PaymentsPaymentTypes.Alcohol = 25 */
    Alcohol = 25,

    /** PaymentsPaymentTypes.Cigarettes = 26 */
    Cigarettes = 26,

    /** PaymentsPaymentTypes.Clothing = 27 */
    Clothing = 27,

    /** PaymentsPaymentTypes.Donations = 28 */
    Donations = 28,

    /** PaymentsPaymentTypes.Gifts = 29 */
    Gifts = 29,

    /** PaymentsPaymentTypes.Hobbies = 30 */
    Hobbies = 30,

    /** PaymentsPaymentTypes.Holidays = 31 */
    Holidays = 31,

    /** PaymentsPaymentTypes.MobilePhone = 32 */
    MobilePhone = 32,

    /** PaymentsPaymentTypes.MoviesDVD = 33 */
    MoviesDVD = 33,

    /** PaymentsPaymentTypes.PersonalGrooming = 34 */
    PersonalGrooming = 34,

    /** PaymentsPaymentTypes.Sport = 35 */
    Sport = 35,

    /** PaymentsPaymentTypes.Subscriptions = 36 */
    Subscriptions = 36,

    /** PaymentsPaymentTypes.OtherPersonalExpenses = 37 */
    OtherPersonalExpenses = 37,

    /** PaymentsPaymentTypes.WaterRates = 38 */
    WaterRates = 38,

    /** PaymentsPaymentTypes.Electricity = 39 */
    Electricity = 39,

    /** PaymentsPaymentTypes.Gas = 40 */
    Gas = 40,

    /** PaymentsPaymentTypes.Internet = 41 */
    Internet = 41,

    /** PaymentsPaymentTypes.Telephone = 42 */
    Telephone = 42,

    /** PaymentsPaymentTypes.CableTV = 43 */
    CableTV = 43,

    /** PaymentsPaymentTypes.OtherUtilityExpenses = 44 */
    OtherUtilityExpenses = 44,

    /** PaymentsPaymentTypes.SchoolFees = 45 */
    SchoolFees = 45,

    /** PaymentsPaymentTypes.Books = 46 */
    Books = 46,

    /** PaymentsPaymentTypes.Uniforms = 47 */
    Uniforms = 47,

    /** PaymentsPaymentTypes.ChildMinding = 48 */
    ChildMinding = 48,

    /** PaymentsPaymentTypes.ExtraCurricularActivities = 49 */
    ExtraCurricularActivities = 49,

    /** PaymentsPaymentTypes.SelfEducation = 50 */
    SelfEducation = 50,

    /** PaymentsPaymentTypes.OtherEducationExpenses = 51 */
    OtherEducationExpenses = 51,

    /** PaymentsPaymentTypes.EatingOut = 52 */
    EatingOut = 52,

    /** PaymentsPaymentTypes.Groceries = 53 */
    Groceries = 53,

    /** PaymentsPaymentTypes.OtherFoodExpenses = 54 */
    OtherFoodExpenses = 54,

    /** PaymentsPaymentTypes.Doctor = 55 */
    Doctor = 55,

    /** PaymentsPaymentTypes.Dentist = 56 */
    Dentist = 56,

    /** PaymentsPaymentTypes.Optometrist = 57 */
    Optometrist = 57,

    /** PaymentsPaymentTypes.AlternativeTherapies = 58 */
    AlternativeTherapies = 58,

    /** PaymentsPaymentTypes.OtherMedicalExpenses = 59 */
    OtherMedicalExpenses = 59,

    /** PaymentsPaymentTypes.Petrol = 60 */
    Petrol = 60,

    /** PaymentsPaymentTypes.PublicTransport = 61 */
    PublicTransport = 61,

    /** PaymentsPaymentTypes.VehicleMaintenanceRepairs = 62 */
    VehicleMaintenanceRepairs = 62,

    /** PaymentsPaymentTypes.VehicleRegistration = 63 */
    VehicleRegistration = 63,

    /** PaymentsPaymentTypes.OtherTransportExpenses = 64 */
    OtherTransportExpenses = 64,

    /** PaymentsPaymentTypes.Rent = 65 */
    Rent = 65,

    /** PaymentsPaymentTypes.LandRates = 66 */
    LandRates = 66,

    /** PaymentsPaymentTypes.MaintenanceRepairs = 67 */
    MaintenanceRepairs = 67,

    /** PaymentsPaymentTypes.OtherHomeExpenses = 68 */
    OtherHomeExpenses = 68,

    /** PaymentsPaymentTypes.OtherExpenses = 69 */
    OtherExpenses = 69,

    /** PaymentsPaymentTypes.Salary = 70 */
    Salary = 70,

    /** PaymentsPaymentTypes.Investment = 71 */
    Investment = 71,

    /** PaymentsPaymentTypes.Centrelink = 72 */
    Centrelink = 72,

    /** PaymentsPaymentTypes.Pension = 73 */
    Pension = 73,

    /** PaymentsPaymentTypes.Business = 74 */
    Business = 74,

    /** PaymentsPaymentTypes.Annuity = 75 */
    Annuity = 75,

    /** PaymentsPaymentTypes.OtherIncome = 76 */
    OtherIncome = 76,

    /** PaymentsPaymentTypes.TaxFreeIncome = 77 */
    TaxFreeIncome = 77,

    /** PaymentsPaymentTypes.RentalIncome = 78 */
    RentalIncome = 78,

    /** PaymentsPaymentTypes.HELPDebtRepayments = 79 */
    HELPDebtRepayments = 79
}
